.rules-list {
	max-height: 40vh;
	overflow-y: scroll;
	padding-inline: 1em;
	margin-bottom: 1em;
	position: relative;
}

.rule-item {
	padding: 1em;
}

.rule-item.is-dragging {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
		rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.rule-item p {
	overflow-wrap: anywhere;
}
