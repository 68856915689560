.profile-card-container {
  text-align: center;
  padding: 2em;
  background-color: #dadada;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin-block: 2em;
}

.profile-card-visuals {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.profile-card-meter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-card-meter > h4 {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
}

.stack {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
