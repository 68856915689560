@font-face {
	font-family: 'Staatliches-Regular';
	src: url('staatliches-regular-webfont.woff') format('woff'),
		url('staatliches-regular-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

h1,
h2 {
	color: #191923;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Staatliches-Regular';
	src: url('staatliches-regular-webfont.woff') format('woff'),
		url('staatliches-regular-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

h2 {
	font-weight: 900;
	font-size: 6em;
}

form {
	max-width: 400px;
	width: 80vw;
}

.container-fluid {
	width: 90vw;
}

.form-container {
	min-width: auto !important;
	min-height: auto !important;
}

.nav-margin {
	padding-bottom: 1em;
}

nav a {
	max-height: 48px;
}

nav a.active {
	position: relative;
}

nav a.active::before {
	content: '';
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 50px;
	background-color: rgba(255, 255, 255, 0.2);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.edit-avatar-container {
	position: relative;
}

.edit-avatar-container > div:nth-of-type(2) {
	position: absolute;
	bottom: 0;
	right: 0;
	border-radius: 100px;
	height: 56px;
	width: 56px;
	z-index: 3;
	opacity: 0;
}

.edit-avatar-container svg {
	position: absolute;
	bottom: 0;
	right: 0;
	border-radius: 100px;
	background-color: #fff;
	border: solid 2px #aaa;
	padding: 16px;
	overflow: visible;
	z-index: 2;
}

.edit-avatar-container svg.save {
	border-color: #fff;
	background-color: #1aa358;
	z-index: 4;
}

.edit-avatar-container input {
	right: 0;
	min-width: 52px;
	min-height: 52px;
	top: 0;
	padding: 0;
	border-radius: 100px;
}

.profile-name-container svg {
	border-radius: 100px;
	background-color: #aaa;
	padding: 10px;
	overflow: visible;
	z-index: 2;
}

.profile-name-container svg.save {
	background-color: #1aa358;
	margin-left: 2em;
}

.all-time-stats-container p {
	position: relative;
	background-color: #fff;
	padding: 8px;
}

.all-time-stats-container .header::after {
	content: '';
	position: absolute;
	height: 4px;
	width: 90vw;
	background-color: #ccc;
	left: 50%;
	top: 50%;
	right: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
}

.confirmation-modal-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.25);
	padding: 2em;
	backdrop-filter: blur(7px);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	transition-duration: 0.3s;
	opacity: 0;
	visibility: hidden;
}

.confirmation-modal-container.show {
	opacity: 1;
	visibility: visible;
}

.confirmation-content {
	padding: 1em;
	background-color: #fff;
	width: 100%;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
		rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	transition-duration: 0.3s;
	opacity: 0;
	visibility: hidden;
}

.confirmation-modal-container.show .confirmation-content {
	opacity: 1;
	visibility: visible;
}
